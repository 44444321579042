.product-detail-card {
  width: 93%;
  max-width: 1200px;
  margin: 15px auto;
}

@media (min-width: 768px) {
  .product-detail-card {
    padding-left: 100px;
    padding-right: 100px;
  }
}